//colors
$primary:     #ad9581;
$primary-dark: #786a5f;
$secondary:   #e5dfd9;
$secondary-dark: #e8d4be;
$lightGrey : #e4e4e4;
.primary-bg{ background-color: $primary;}
.secondary-bg{ background-color: $secondary;}
.white-bg{ background-color: #fff !important;}

//FONT FAMILIES
$font-family-base: 'Lato', sans-serif;
$font-hand : 'Redressed', cursive;


// FLASHES
$bgFlashSuccess: #00d7ab;
$colorFlashSuccess: darken($bgFlashSuccess, 30%);

$bgFlashNotice: #d74f00e1;
$colorFlashNotice: darken($bgFlashNotice, 30%);

$bgFlashDanger: #ff2525;
$colorFlashDanger: #6d0a0a;
