.slider{

  .carousel{
    position: relative;

    h1{

      @include media-breakpoint-down(sm) {
        display: none;
      }

      position: absolute;
      z-index: 10;
      bottom: 50px;
      left: 100px;
      text-align: center;
      line-height: 1.1;
    }
  }//carousel

}//slider