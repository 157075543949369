//Bloc Facebook
.facebookMod {
  padding: 2rem;
  background: rgba(255, 255, 255, 0.7);
  min-height: 500px;
  max-width: 360px;
  margin: 0 auto;

  /* Cet élément block le script injecté dans l'iframe. On le cache */
  #fb-root {
    display: none;
  } /* on redéfini la taille du contenu */
  .fb_iframe_widget,
  .fb_iframe_widget span,
  .fb_iframe_widget span iframe[style] {
    width: 100% !important;
  }
}
.page-contact {
  h2,
  h3 {
    text-transform: uppercase;
    font-weight: $font-weight-normal;
  }

  .horaires-box {
    padding: 25px;
    border: 1px solid $secondary;
  }

  .main-aside,
  .second-aside {
    color: $primary;
    font-size: $font-size-base;
  }

  .second-aside {
    h4 {
      font-size: $font-size-lg;
    }

    strong {
      color: $primary-dark;
    }

    .facebook-link {
      font-size: 36px;
    }
  }

  #ggmap {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  } //ggmap

  .border-bg {
    border-bottom: 50px solid $secondary;
  }
} //page-contact
