//IMPORT
//libs
@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@splidejs/splide/dist/css/splide.min.css";
@import "~@splidejs/splide/dist/css/themes/splide-skyblue.min.css";
//vars
@import "helper/variables.scss";
//commons
@import "common/header.scss";
@import "common/footer.scss";
@import "common/flash.scss";
//Custom libs
@import "libs/cookies.scss";
@import "libs/splide.scss";
//Components
@import "components/animation.scss";
@import "components/parallaxeheader.scss";
@import "components/carousel.scss";
@import "components/featurettes.scss";
//pages
@import "pages/home.scss";
@import "pages/presentation.scss";
@import "pages/contact.scss";

// Bootstrap VARS

// Full list : node_modules/bootstrap/scss/_variables.scss

// COMMENT / UNCOMMENT VsCODE UBUNTU: CTRL+Schift+A

/*$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;  */

// Body
//
// Settings for the `<body>` element.
/* $body-bg: $white !default;
$body-color: $gray-900 !default; */

// Links
//
// Style anchor elements.

//$link-color: theme-color("primary") !default;
/* $link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default; */

// Paragraphs
//
// Style p element.

/* $paragraph-margin-bottom: 1rem !default; */

// Components
//
// Define common padding and border radius sizes and more.

/* $line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;

$border-width: 1px !default;
$border-color: $gray-300 !default;

$border-radius: 0.25rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.2rem !default;

$rounded-pill: 50rem !default;

$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;

$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-base: $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * 0.875 !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$headings-margin-bottom: $spacer / 2 !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default; */

// Common styles
h1{
  color: $primary;
  text-transform: uppercase;
}

.text-primary{
  color: $primary !important;
}

a{
  color: $primary;

  &:hover{
    color: darken($primary, 5%);
  }
}
main{

  .main-aside{
    color: $primary-dark;
    background: $secondary;
  }

  .main-content{
    color: $white;
    background: $primary;
  }

  .full-content{
    color: $primary-dark;
    background: $secondary;

    h2{
      color: $primary;
      text-transform: uppercase ;
    }

    .menu-list{
      li{
        font-size: $font-size-lg;
        padding: 10px 0;
        border-bottom: 1px solid $white;
      }

    }
  }

}

.btn-primary,
.btn-primary:hover{
  background: $primary !important;
  border-color: $primary !important;
}

// Import custom styles
// See assets/app.js :
// import './styles/pages/home.scss';
