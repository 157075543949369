.page-home{

  .photo-container{

    @include media-breakpoint-down(sm) {
      display: none;
    }

    @include media-breakpoint-up(md) {
      height: 120px;
    }

    @include media-breakpoint-up(lg) {
      height: 180px;
    }


    &.home-photos-left{
      background-image: url('/public/images/pages/home-photos-left.jpg');
      background-size: cover;
    }

    &.home-photos-right{
      background-image: url('/public/images/pages/home-photos-right.jpg');
      background-size: cover;
    }
  }


  .main-aside{
    font-size: $font-size-lg;

    h2{
      text-transform: uppercase;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: .4rem;
      color: $primary;

      span{
        display: block;
        font-size: 40%;
      }
    }

    p{
      font-weight: bold;
      &:after{
        content: "___";
        display: block;
        color: $primary;
      }

      &.last{
        &:after{
          content: '';
          display: none;
        }
      }
    }

    .facebook-link{
      display: inline-block;
    }
    svg{
      font-size: 60px !important;
      color: $white;

      &:hover{
        color: $primary;
      }
    }
  }

  .main-content{
    font-size: $font-size-lg;
    h3{
      color: $secondary-dark;
      text-transform: uppercase;
    }
  }
}