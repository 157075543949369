//Shaking logo
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }
  
  //Back to top
  .top-link {
    transition: all .25s ease-in-out;
    position: fixed;
    bottom: 0;
    right: 0;
    display: inline-flex;
    z-index: 100;
  
    cursor: pointer;
    align-items: center;
    justify-content: center;
    margin: 0 3em 3em 0;
    border-radius: 10px;
    padding: .25em;
    width: 40px;
    height: 40px;
    background-color: #ddd;
  
    &.show {
      visibility: visible;
      opacity: 1;
    }
  
    &.hide {
      visibility: hidden;
      opacity: 0;
    }
  
    svg {
      fill: $primary;
      width: 10px;
      height: 12px;
    }
  
    &:hover {
      background-color: #E8E8E8;
  
      svg {
        fill: $primary;
      }
    }
  }
  
  // Text meant only for screen readers.
  .screen-reader-text {
    position: absolute;
    clip-path: inset(50%);
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    word-wrap: normal !important;
    clip: rect(1px, 1px, 1px, 1px);
  
    &:focus {
      display: block;
      top: 5px;
      left: 5px;
      z-index: 100000; // Above WP toolbar
      clip-path: none;
      background-color: #eee;
      padding: 15px 23px 14px;
      width: auto;
      height: auto;
      text-decoration: none;
      line-height: normal;
      color: #444;
      font-size: 1em;
      clip: auto !important;
    }
  }