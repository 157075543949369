header {
  .header-top {
    height: 10px;
    background-color: $secondary;

    @include media-breakpoint-up(md) {
      height: 40px;
    }
  } //header top

  .main-menu {
    li {
      margin: 0 !important;
      padding: 0;
    }

    a {
      display: block;
      padding: 1rem;
      text-decoration: none;

      @include media-breakpoint-up(md) {
        padding: 2rem 1rem 1rem;
      }

      @include media-breakpoint-up(lg) {
        font-size: $lead-font-size;
        padding: 2rem 1.5rem 1rem;
      }

      &.active {
        color: $white;
        background: $primary;

        &:hover {
          color: $primary;
        }
      } //active

      &:hover {
        background: $secondary;
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 1) 0%,
          rgba($secondary, 1) 90%
        );
      }

      &.logo {
        padding: 0;
        margin: 0;
        position: relative;
        display: block;

        &:hover {
          background: transparent;
        }

        img {
          width: 100px;
          @include media-breakpoint-up(md) {
            width: 180px;
            position: absolute;
            top: -75px;
            left: 25px;
            z-index: 10;
          }

          @include media-breakpoint-up(lg) {
            left: 75px;
          }

          @include media-breakpoint-up(xl) {
            left: 100px;
          }

          &:hover {
            /* Start the shake animation and make the animation last for 0.5 seconds */
            animation: shake 0.5s;

            /* When the animation is finished, start again */
            animation-iteration-count: 3s;
          }
        } //img
      } //.logo
    } //a
  } //main-menu
} //header

.header-image {
  img {
    max-width: 100%;
  }

  h1 {
    color: $white;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
    font-size: 18px;
    font-weight: 400;

    @include media-breakpoint-up(md) {
      font-size: 24px;
    }

    span {
      display: block;
      font-family: $font-hand;
      text-transform: none;
      font-size: 56px;

      @include media-breakpoint-up(md) {
        font-size: 72px;
      }
    }
  } //h1

  &.home-header {
    height: 200px;
    color: $white;
    background-image: url("/public/images/pages/home_photo.jpg");
    background-size: cover;

    @include media-breakpoint-up(md) {
      height: 300px;
    }

    @include media-breakpoint-up(lg) {
      height: 500px;
    }
  } //home-header

  &.contact-header {
    height: 500px;
    color: $white;
    background-image: url("/public/images/pages/home_photo.jpg");
    background-size: cover;
    background-position: center center;
  } //contact-header
} // header-image
