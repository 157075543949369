/* Position reference */
body {
  position: relative;
}

/*Animation */
@keyframes slideInFromTop {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideOutFromTop {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.js-flash {
  animation: 0.2s ease-out 0s 1 slideInFromTop;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: $zindex-tooltip;
}

.js-flash--close {
  //animation: 0.2s ease-out 0s 1 slideOutFromTop;
  display: none;
}

#flash-close {
  cursor: pointer;

  &:hover {
    color: #fff;
  }
}

.flash {
  padding: 2rem 0;
}

.flash-success {
  background: rgba($bgFlashSuccess, 0.85);
  color: darken($bgFlashSuccess, 30);
}

.flash-notice {
  background: rgba($bgFlashNotice, 0.85);
  color: darken($bgFlashNotice, 30);
}

.flash-danger {
  background: rgba($bgFlashDanger, 0.85);
  color: #fff;
}
