//Override lib style
.splide__slide {
  padding-bottom: 2em;
  background: #f4f4f4;
}

.splide__pagination {
  bottom: 2em;
}

.splide__arrow svg {
  fill: #fff;
}
