.parallax-wrapper {
  overflow-y: hidden;
}

.parallax {
  background-size: cover;
  background-position: center;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  h1,
  p {
    margin: 0;
    color: #fff;
  }

  p {
    font-weight: 500;
    font-size: 1.2em;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    line-height: 1.6;
    padding-top: 80px;
  }
}
