.prefooter{
    .label-bio{
      position: relative;
      top: -50px;
    }
  
    .prefooter-address{
      color: $primary;
  
      strong{
        color: $primary-dark;
        font-size: $font-size-lg;
      }
    }
  }
  
  .footer-info{
    color: $white;
  }